import * as ReactDOMClient from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';

const container = document.getElementById("root");

const client = new ApolloClient({
  uri: 'https://admin.kondodycarworld.com/graphql', // Replace with your Strapi GraphQL API endpoint
  cache: new InMemoryCache()
});

// Create a root.
const root = ReactDOMClient.createRoot(container);

root.render(
  <BrowserRouter>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </BrowserRouter>
);
