export default [
  {
    color: "light",
    name: "Antony George",
    date: "a year ago",
    review:
      "A very genuine n hassle free process, for any customer planning to purchase a second hand car from Teddy.....I have purchased few cars from him n have been a very satisfied customer of his for several years....i have also recommended many of my friends who have purchased cars from him n all of them are extremely happy till date not only with the quality of the cars but also with the buying process as well.....Kudos n keep up the good work Teddy n Kondody Car World👍👏👌!!!",
    rating: 4.5,
  },
  {
    color: "dark",
    name: "Tony Chacko",
    date: "a year ago",
    review:
      "One of the best pre owned luxury brands cars dealers in kerala …my experience with kondody car world is more than 10 years..I’ve changed many cars …they always meet my expectations…More than business it’s a personal relationship we can build with them ….my trusted car partner for last ten years…kudus to the Team kondody car world…Thank u Mr Tom teddy kondody 🙏🏻",
    rating: 5,
  },
  {
    color: "light",
    name: "Rachel Basil",
    date: "3 years ago",
    review:
      "Best service ever provided.Thanks for dealing with our needs with almost concern. N I personally feel that kondody car world is having one of the best premium car collection in town.",
    rating: 3.5,
  },
];
