import { useEffect, useState } from 'react';
import {useParams} from 'react-router-dom';

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
// import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// About Us page sections;
// import CarCardList from "./sections/CarCardList";
import VehicleDetails from "./sections/VehicleDetails";
import AboutVehicle from "./sections/AboutVehicle";
import VehicleData from "./sections/VehicleData";
import CarImageLightbox from "pages/LandingPages/Home/sections/CarImageLightbox";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/bg_3.jpg";

// Gifs
import LoadingGif from "assets/gifs/car-loading-gif-16.gif"

export default function Cars() {

  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const {id} = useParams();

  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      try {
        const response = await fetch(`https://admin.kondodycarworld.com/api/vehicle-details/${id}?populate=*`);
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const jsonData = await response.json();
        setData(jsonData);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError(err);
      } finally {
        setIsLoading(false);
      }
    };
  
    // Call the fetchData function when the component is loaded
    fetchData();
  }, []);

  return (
    <>
      <DefaultNavbar
        routes={routes}
        // action={{
        //   type: "external",
        //   route: "https://www.creative-tim.com/product/material-kit-react",
        //   label: "free download",
        //   color: "default",
        // }}
        transparent
        light
      />
      <MKBox
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
          minHeight: {
            xs: '50vh', // For mobile (smallest breakpoint)
            sm: '75vh', // For tablets (small breakpoint)
            lg: '75vh', // For larger devices (medium breakpoint and above)
          },
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Car Details
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        {isLoading && 
        <Grid className="h-100" lg={12}>
          <div style={{display: 'flex', justifyContent: 'center'}}>
            <img src={LoadingGif} alt="Loading.." />
          </div>
        </Grid>
      }
        {error && <p>Error fetching data: {error.message}</p>}
        {data&& 
          <VehicleDetails data={data}/>
        }
        <AboutVehicle desc={data?.data.attributes.about}/>
        <VehicleData />
        <Grid container item sx={{ overflow: "hidden" }}>
          {data&& 
            <CarImageLightbox data={data.data.attributes.images}/>
          }  
        </Grid>
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}
