// @mui material components
import Grid from "@mui/material/Grid";

// style
import style from "./VehicleMainImage.module.css";

export default function VehicleMainImage({image}) {
  const vehicleUrl = image.data.attributes.url
  
  return (
    <Grid container itempb={3} data-aos="fade-up">
      <img
        src={`https://admin.kondodycarworld.com/${vehicleUrl}`  }
        alt="Girl in a jacket"
        width="100%"
        // height="600"
        className={style.banner_img}
      />
    </Grid>
  );
}
