import { useEffect } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Style
import style from "./AboutMe.module.css";

// Animation
import AOS from "aos";
import "aos/dist/aos.css";


function AboutMe() {
  useEffect(() => {
    AOS.init({ duration: 1800 }); // Initialize AOS with options (adjust duration as needed)
  }, []);
  return (
    <MKBox component="section" pt={3} pb={8} className={style.bgImage}>
      <Grid className={style.parallaxDiv}>
        <Container data-aos="fade-up" className="p-lg-5 p-4">
          <Grid className={style.mainGrid}>
            <Grid container pb={{xs: 5}}>
              <Grid md={12} lg={8} item sx={{ margin: "auto" }}>
                <MKTypography
                  // fontWeight="medium"
                  color="light"
                  variant="body1"
                  className={style.aboutDiv}
                >
                  I Am overwhelmed with gratitude and pride from our 25+ years of journey in Kolkata and Kochi was very exited and Adventurous for us.
                 We deal in all new and Used cars from all over India with quality and Trust. As I reflect on the incredible journey of Kondody car world has been nothing short of excitement and Adventures
                </MKTypography>

              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Grid>
    </MKBox>
  );
}

export default AboutMe;
