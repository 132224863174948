import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

// Icons
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Sweet Alert
import Swal from 'sweetalert2'


// Images
import bgImage from "assets/images/contact-us/contact-info.webp";

function Contact() {
  const formRef = useRef();
  // const [formValues, setFormValues] = useState({ name: "", email: "", message: "" });
  const [formValues, setFormValues] = useState({
    from_name: "",
    phone: "",
    message: "",
  });
  const [errors, setErrors] = useState({
    from_name: false,
    phone: false,
    message: false,
  });
  // const [successMessage, setSuccessMessage] = useState("");
  // const [errorMessage, setErrorMessage] = useState("");

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value, // Dynamically update the field based on the name attribute
    }));
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formValues.from_name.trim()) newErrors.from_name = "Name is required.";
    if (!formValues.email.trim()) {
      newErrors.email = "Email is required.";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formValues.email)) {
      newErrors.email = "Enter a valid email address.";
    }
    if (!formValues.phone.trim() || !/^\d{10}$/.test(formValues.phone)) {
      newErrors.phone = "Valid 10-digit phone number is required.";
    }
    if (!formValues.message.trim()) newErrors.message = "Message is required.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const sendEmail = (e) => {
    e.preventDefault();
    
    if (!validateForm()) return;
    
    emailjs
      .sendForm(
        "service_7yu9ljx", // EmailJS Service ID
        "template_ttyblio", //  EmailJS Template ID
        formRef.current,
        "qq_pmMJ5OOIWVRDzN"  // EmailJS Public Key
      )
      .then(
        () => {
          // console.log("Email sent successfully:", result.text);
          Swal.fire({
            title: "Success!",
            text: "Message sent successfully!",
            icon: "success",
            confirmButtonText: "OK",
          });
          formRef.current.reset();
          setFormValues({ from_name: "", phone: "", message: "" });
        },
        () => {
          Swal.fire({
            title: "Error",
            text: "Failed to send the message. Please try again.",
            icon: "error",
            confirmButtonText: "Retry",
          });
        }
      );
  };
  

  return (
    <MKBox component="section" py={{ xs: 0, lg: 6 }}>
      <GoogleReCaptchaProvider reCaptchaKey="6Lc7p7AqAAAAALTNsEcCjplDwUYeYG3_jjcagowP">
        <Container sx={{ paddingRight: 0, paddingLeft: 0 }}>
          <Grid container item>
            <MKBox
              width="100%"
              bgColor="white"
              borderRadius="xl"
              shadow="xl"
              mb={6}
              sx={{ overflow: "hidden" }}
            >
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  lg={5}
                  position="relative"
                  px={0}
                  sx={{
                    backgroundImage: ({
                      palette: { gradients },
                      functions: { rgba, linearGradient },
                    }) =>
                      `${linearGradient(
                        rgba(gradients.dark.main, 0.8),
                        rgba(gradients.dark.state, 1.2)
                      )}, url(${bgImage})`,
                    backgroundSize: "cover",
                  }}
                >
                  {/* Contact Information Section */}
                  <MKBox
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width="100%"
                    height="100%"
                  >
                    <MKBox py={{ lg: 6, xs: 2 }} px={{ lg: 6, xs: 2 }} my="auto">
                      <MKTypography variant="h3" color="white" mb={1}>
                        Contact Information
                      </MKTypography>
                      <MKTypography variant="body2" color="white" opacity={0.8} mb={3}>
                        Fill up the form and our Team will get back to you within 24 hours.
                      </MKTypography>
                      <MKTypography variant="body2" color="white" opacity={0.8} mb={3}>
                        <MKBox
                          display="flex"
                          alignItems="center"
                          gap={1}
                          component="a"
                          href="tel:+91 9037063837"
                          sx={{
                            textDecoration: "none",
                            color: "#ffffff",
                            fontSize: "18px",
                            "&:hover": { color: "#FFD700" }, // Optional hover effect
                          }}
                        >
                          <CallIcon /> 
                          +91 9037063837
                        </MKBox>
                      </MKTypography>
                      <MKTypography variant="body2" color="white" opacity={0.8} mb={3}>
                        <MKBox
                          display="flex"
                          alignItems="center"
                          gap={1}
                          component="a"
                          href="mailto:teddy_kondody@hotmail.com"
                          sx={{
                            textDecoration: "none",
                            color: "#ffffff",
                            fontSize: "18px",
                            "&:hover": { color: "#FFD700" }, // Optional hover effect
                          }}
                        >
                          <EmailIcon /> 
                          teddy_kondody@hotmail.com
                        </MKBox>
                      </MKTypography>
                    </MKBox>
                  </MKBox>
                </Grid>
                <Grid item xs={12} lg={7}>
                  <MKBox component="form" ref={formRef} onSubmit={sendEmail}>
                    <MKBox py={{ lg: 6, xs: 2 }} px={{ lg: 6, xs: 2 }}>
                      <MKTypography variant="h2" mb={1}>
                        Say Hi!
                      </MKTypography>
                      <MKTypography variant="body1" color="text" mb={2}>
                        We&apos;d like to talk with you.
                      </MKTypography>
                    </MKBox>
                    <MKBox py={{ lg: 6, xs: 2 }} px={{ lg: 6, xs: 2 }}>
                      <Grid container>
                        {/* Name Input */}
                        <Grid item xs={12} pr={1} mb={6}>
                          <MKInput
                            variant="standard"
                            label="My name is"
                            name="from_name"
                            value={formValues.name}
                            onChange={handleInputChange}
                            placeholder="Full Name"
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            error={Boolean(errors.name)}
                          />
                          {errors.name && (
                            <MKTypography variant="caption" color="error">
                              {errors.name}
                            </MKTypography>
                          )}
                        </Grid>

                        {/* Email Input */}
                        <Grid item xs={12} sm={6} pr={1} mb={6}>
                          <MKInput
                            variant="standard"
                            label="Email"
                            name="email"
                            value={formValues.email}
                            onChange={handleInputChange}
                            placeholder="Your Email"
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            error={Boolean(errors.email)}
                          />
                          {errors.email && (
                            <MKTypography variant="caption" color="error">
                              {errors.email}
                            </MKTypography>
                          )}
                        </Grid>
                        
                        {/* phone Input */}
                        <Grid item xs={12} sm={6} pr={1} mb={6}>
                        <MKInput
                          variant="standard"
                          label="Phone Number"
                          name="phone"
                          value={formValues.phone}
                          onChange={handleInputChange}
                          placeholder="Enter your phone number"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          error={Boolean(errors.phone)}
                          helperText={errors.phone && "Please enter a valid phone number."}
                        />
                          {errors.phone && (
                            <MKTypography variant="caption" color="error">
                              {errors.phone}
                            </MKTypography>
                          )}
                        </Grid>

                        {/* Message Input */}
                        <Grid item xs={12} pr={1} mb={6}>
                          <MKInput
                            variant="standard"
                            label="Your message"
                            name="message"
                            value={formValues.message}
                            onChange={handleInputChange}
                            placeholder="I want to say that..."
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            multiline
                            rows={6}
                            error={Boolean(errors.message)}
                          />
                          {errors.message && (
                            <MKTypography variant="caption" color="error">
                              {errors.message}
                            </MKTypography>
                          )}
                        </Grid>
                      </Grid>

                      {/* Submit Button */}
                      <Grid
                        container
                        item
                        xs={12}
                        md={6}
                        justifyContent="flex-end"
                        textAlign="right"
                        ml="auto"
                      >
                        <MKButton type="submit" variant="gradient" color="info">
                          Send Message
                        </MKButton>
                      </Grid>
                    </MKBox>
                    {/* Success/Failure Messages */}
                    {/* <MKBox>
                      {successMessage && (
                        // <MKTypography variant="body2" color="success" mt={2}>
                        //   {successMessage}
                        // </MKTypography>
                        Swal.fire({
                          title: "Good job!",
                          text: "You clicked the button!",
                          icon: "success"
                        })
                      )}
                      {errorMessage && (
                        <MKTypography variant="body2" color="error" mt={2}>
                          {errorMessage}
                        </MKTypography>
                      )}
                    </MKBox> */}
                  </MKBox>
                </Grid>
              </Grid>
            </MKBox>
          </Grid>
        </Container>
      </GoogleReCaptchaProvider>
    </MKBox>
  );
}

export default Contact;
