import { useEffect } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";

// Style
import style from "./VehicleCounter.module.css";

// Animation
import AOS from "aos";
import "aos/dist/aos.css";

export default function VehicleCounter() {
  useEffect(() => {
    AOS.init({ duration: 1800 }); // Initialize AOS with options (adjust duration as needed)
  }, []);
  return (
    <MKBox component="section" py={3} data-aos="fade-up">
      <Container sx={{ paddingRight: 0, paddingLeft: 0 }}>
        <Grid container item xs={12} lg={9} sx={{ mx: "auto" }}>
          <Grid item xs={12} md={4} sm={4}>
            <DefaultCounterCard
              count={25}
              suffix="+"
              title="Years"
              description=""
            />
          </Grid>
          <Grid item xs={12} md={4} sm={4} display="flex" className={style.soldVehiclesCard}>
            <Divider orientation="vertical" sx={{ display: { xs: "none", md: "block" }, mx: 0 }} />
            <DefaultCounterCard
              count={2500}
              suffix="+"
              title="Sold Vehicles"
              description=""
            />
            <Divider orientation="vertical" sx={{ display: { xs: "none", md: "block" }, ml: 0 }} />
          </Grid>
          <Grid item xs={12} md={4} sm={4}>
            <DefaultCounterCard
              count={2500}
              suffix="+"
              title="Happy Customers"
              description=""
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}
