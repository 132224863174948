import { useEffect, useState } from "react";
// @mui material components
// import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
// import MKBox from "components/MKBox";
// import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";

// Animation
import AOS from "aos";
import "aos/dist/aos.css";

import VehicleListCard from "pages/VehicleList.js/sections/VehicleListCard";

// Gifs
import LoadingGif from "assets/gifs/car-loading-gif-16.gif"

// GraphQL
import { useQuery, gql } from '@apollo/client';

const GET_LATEST_VEHICLE_DETAILS = gql`
query {
  vehicleDetails(
    sort: "createdAt:desc"
    pagination: {
      limit: 6
    }
  ) {
    data {
      id
      attributes {
        vehicle_name
        model
        main {
          data {
            attributes {
              url
            }
          }
        }
        fuel
        distance
        price
      }
    }
  }
}
`;

export default function NewestVehicles() {

  const [list, setList] = useState([]);
  const { loading, error, data } = useQuery(GET_LATEST_VEHICLE_DETAILS);

  useEffect(() => {
    if(data && data.vehicleDetails) {
    setList(data.vehicleDetails.data)
  }
  }, [data])
  
  useEffect(() => {
    AOS.init({ duration: 2000 }); // Initialize AOS with options (adjust duration as needed)
  }, []);

  return (
    <>     
      {error && 
        <Grid className="d-flex justify-content-center align-items-center h-100">
          Error
        </Grid>
      }
      { loading && 
        <Grid className="d-flex justify-content-center align-items-center h-100" lg={12}>
          <div>
            <img src={LoadingGif} alt="Loading.." />
          </div>
        </Grid>
      }
      {list && 
        <VehicleListCard data={list}/>
      }
    </>
  );
}
