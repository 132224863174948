import { useState, useEffect } from "react";
import Lightbox from "react-18-image-lightbox";
import "react-18-image-lightbox/style.css"; // Import the styles

import style from "./CarImageLightbox.module.css";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Animation imports
import AOS from "aos";
import "aos/dist/aos.css";

const CarImageLightbox = ({ data }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  useEffect(() => {
    AOS.init({ duration: 1500 }); // Initialize AOS with options (adjust duration as needed)
  }, []);

  // Extract image URLs for the lightbox
  const images = data?.data.map(
    (item) => `https://admin.kondodycarworld.com${item.attributes.url}`
  );

  const openLightbox = (index) => {
    setIsOpen(true);
    setPhotoIndex(index);
  };

  const closeLightbox = () => {
    setIsOpen(false);
  };

  return (
    <MKBox component="section" pt={5} pb={8} width="100%" data-aos="fade-left">
      <Container>
        <MKTypography variant="h2" pb={3}>
          Image Gallery
        </MKTypography>
        <Grid className={style.image_container} container spacing={2}>
          {data?.data.map((image, index) => (
            <Grid item xs={6} sm={4} md={3} key={image.id}>
              <img
                src={`https://admin.kondodycarworld.com${image.attributes.url}`}
                width="100%"
                alt={`Image ${index}`}
                onClick={() => openLightbox(index)}
                className={style.image_grid}
              />
            </Grid>
          ))}
        </Grid>
        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={closeLightbox}
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + images.length - 1) % images.length)
            }
            onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
          />
        )}
      </Container>
    </MKBox>
  );
};

export default CarImageLightbox;
